import Swal from 'sweetalert2'
import { ajax } from "@rails/ujs"

export default function (message, element){
  const taskURL = element.getAttribute('href');
  const hardConfirm = element.getAttribute('data-hardConfirm')

  if (hardConfirm === undefined || !!!hardConfirm){
    return window.confirm(message);
  }

  Swal.fire({
    title: message,
    input: 'text',
    inputLabel: 'para excluir digite a palavra Apagar no campo abaixo',
    inputPlaceholder: '"Apagar"',
    showCancelButton: true,
  }).then(
    function(value){
      if (value.isConfirmed && value.value === 'Apagar'){
        ajax({
          type: "DELETE",
          url: taskURL +'&destroy_confirmation=true'
        })
      }
     }
  )

  return false;
}

// export default function(element, onConfirm) {
//   const options = JSON.parse(element.getAttribute('data-sweet-alert') || '{}');
//   const message = element.getAttribute('data-confirm');

//   Swal.fire({
//     title: 'Are you sure?',
//     text: message,
//     icon: 'warning',
//     showCancelButton: true,
//     confirmButtonText: 'Confirm',
//     confirmButtonColor: '#3085d6',
//     cancelButtonColor: '#d33',
//     ...options,
//   }).then((result) => {
//     if (result.isConfirmed) {
//       onConfirm();
//     }
//   });
// }

