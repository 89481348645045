const Swal = require('sweetalert2');

class AjaxValidationElement {
  element;

  constructor(element) {
    this.element = $(element);
    this.element.on('focusout', this.validates.bind(this) )

    this.validate_url = this.element.data('ajax-validation')
    this.params_name = this.element.data('ajax-validation-param')+'_eq'
    this.execept_id = this.element.data('ajax-validation-execept-id')
    this.message = this.element.data('ajax-validation-message')

    console.log(this.element, this.validate_url, this.params_name)
  }

  validates(e) {
    let params = {}
    if (this.element.val().length < 2 ){ return }

    params[this.params_name] = this.element.val();
    $.getJSON(this.validate_url, {q: params}, this.response_data.bind(this))
  }

  response_data(data){
    if (data.array.length > 0){
      if (data.array.length === 1 && data.array[0].id === this.execept_id) { return }
      let data_text = ''

      data.array.forEach( (item) => { data_text = data_text + item.text + " \n " } )

      Swal.fire({
        title: 'Alerta!',
        text: this.message + " \n\n\n " + data_text,
        icon: 'warning',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#007bff'
      })

    }
  }

}

const AjaxValidation = function(){
    var pub = {};

    pub.init = () => {
      $('input[data-ajax-validation]').each(
        (i, elementor) => new AjaxValidationElement(elementor)
      )
    }

  return pub;
  }()

export default AjaxValidation
