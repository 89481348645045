const Charges = function() {
  var pub = {};
  var priv = {};
  var to_float = window.poloni.utils.to_float;

  pub.init = function(field_id) {
    priv.field_id = field_id;

    $('#charge_discount').on('blur', priv.recalculate_charge)
    $('#charge_item').on('cocoon:before-insert', priv.add_charge_item).on('cocoon:after-remove', priv.recalculate_charge)

    $('input.currency').each(function(){
      $(this).on('blur', priv.recalculate_charge)
    })
  }

  priv.add_charge_item = function(e,task_to_be_added){
    task_to_be_added.find('input.currency').each(function(){
      $(this).on('blur', priv.recalculate_charge)
    })
  }

  priv.set_currency_val = function(field, total) {
    total = total.toFixed(2).replace('.',',')
    field.unmask().val(total)
    window.poloni.utils.mask.currency.maskField(field)
  }

  priv.recalculate_charge = function() {
    let tval = priv.calculate_total_field('total_value');
    let valor_antecipado = priv.calculate_total_field('upfront_value');

    let total_discount = priv.calculate_total_field('discount')

    let total_cost = priv.calculate_total_field('cost_amount')
    let total_fee = priv.calculate_total_field('fee_amount')
    let total_nfse = priv.calculate_total_field('nfse_amount')

    priv.set_currency_val($('#'+priv.field_id+'_cost_amount'), total_cost)
    priv.set_currency_val($('#'+priv.field_id+'_fee_amount'), total_fee)
    priv.set_currency_val($('#'+priv.field_id+'_nfse_amount'), total_nfse)

    let val = tval;
    let total = (val - total_discount);

    priv.set_currency_val($('#'+priv.field_id+'_discount'), total_discount)
    priv.set_currency_val($('#'+priv.field_id+'_total_value'), total)
    priv.set_currency_val($('#'+priv.field_id+'_value'), val)
  }

  priv.calculate_total_field = function(field){
    let total_amount_p = 0;
    let total_amount_n = 0;

    $("input[name^='"+priv.field_id+"\[charge_items_attributes\]'][name*='\[" + field + "\]']").each(function(){
      if (this.value < 0){
        total_amount_n += to_float(this.value)
      } else {
        total_amount_p += to_float(this.value)
      }
    })

    let total_amount = total_amount_p - total_amount_n;

    return total_amount;
  }

  priv.changed_order_id = function(e) {
    let id = e.currentTarget.id.replace('_order_id','');
    let value_field = $('#'+id+'_value');
    let upfront_value_field = $('#'+id+'_upfront_value');
    let total_value_field = $('#'+id+'_total_value');
    let service_ids_field = $('#'+id+'_service_ids');
    let data = e.params.data;

    priv.set_currency_val(value_field, data.total_amount)
    priv.set_currency_val(upfront_value_field, data.upfront_value)
    priv.set_currency_val(total_value_field, data.total_amount - data.upfront_value)

    var params = service_ids_field.data('params');
    params['order_id_eq'] = data.id;
    service_ids_field.data('params', params);

    priv.recalculate_charge()
  }

  priv.removed_order_id = function(e) {
    priv.recalculate_charge()
  }

  const service_reducer = (accumulator, currentValue) => accumulator + currentValue.total_amount

  priv.changed_services = function(e){
    let id = e.currentTarget.id.replace('_service_ids','');
    let value_field = $('#'+id+'_value');
    let total_value_field = $('#'+id+'_total_value');

    let upfront_value = to_float($('#'+id+'_upfront_value').val());
    let total_value = $(e.currentTarget).select2('data').reduce(service_reducer, 0)

    priv.set_currency_val(value_field, total_value)
    priv.set_currency_val(total_value_field, total_value)

    priv.recalculate_charge()
  }

  priv.removed_changed_services = function(e){
    let id = e.currentTarget.id.replace('_service_ids','');
    let order_id_field = $('#'+id+'_order_id');

    order_id_field.trigger('change');
  }

  return pub;
}()

export default Charges
