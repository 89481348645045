import flatpickr from "flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js"
const toastr = require("toastr");
import "jquery-mask-plugin";
import moment from 'moment';
const Swal = require('sweetalert2');

const Utils =  function($) {
  $(document).on("turbolinks:before-cache", function() {
    window.poloni.save = false;

    $('button[name=commit]').show();
    $('button[name=saving]').hide();

    try { $("select.select2").select2('destroy'); } catch { return true }
  });

  $(document).on("turbolinks:load", function() {
    $.fn.select2.defaults.set("theme", "bootstrap4");
    $.fn.select2.defaults.set("language", "pt-BR");
    $.fn.select2.defaults.set("placeholder", "Selecione");
    $.fn.select2.defaults.set("allowClear", "true");

    window.poloni.utils.mask.currency.maskField('input.currency');
    window.poloni.utils.mask.currency.formatInitFields('input.currency');

    $('select#per_page').on('change', changePerPage);

    $('input.phone').mask('(00) 0000-00009')
    $('input.cpf').mask('000.000.000-00', {reverse: true})
    $('input.cnpj').mask('00.000.000/0000-00', {reverse: true})

    window.poloni.save = false

    $('button[name=commit]').on('click', waitSubmiting)

    let fnCpfCnpj = function(value){
      value = value.replaceAll(/\D/g,'');

      var masks = ['000.000.000-009999', '00.000.000/0000-00'];
      return masks[(value.length > 11 ? 1 : 0)];
    }

    let optionsCPFCNPJ = {
      onKeyPress: function(value, e, field, options){
        field.mask(fnCpfCnpj.apply({}, arguments), options);
      }
    };
    $('input.cpf_cnpj').mask(fnCpfCnpj, optionsCPFCNPJ);

    $("select.select2").each(function(){
      var select2_field = $(this);
      if (select2_field.data('url')){
        // select2_field.select2({theme: "bootstrap4",language: "pt-BR"})
        window.poloni.utils.select2_ajax.select2_ajax_request.init(select2_field, {theme: "bootstrap4",language: "pt-BR"})
      } else {
        select2_field.select2()
      }
    })

    let cepOptions =  {
      onComplete: (cep, event, currentField) =>{
        let card = currentField.parents('.card')

        window.cep(cep).then( (value) => {
          card.find("[name$='[city]']").val(value.city)
          card.find("[name$='[address]']").val(value.street)
          card.find("[name$='[district]']").val(value.neighborhood)
          card.find("[name$='[state]']").val(value.state)
        }).catch( (error) => {
            if (error.type === 'service_error' && error.errors.length > 1){
              toastr.error('CEP não encontrado ou serviço indisponível.', 'Erro!')
            } else if (error.type === 'validation_error') {
              toastr.error(error.message, 'Erro!')
            }
          }
        )

      }
    }
    $('input.cep').mask('00000-000',cepOptions)

    flatpickr("input.date_picker", {dateFormat: 'd/m/Y', "locale": Portuguese, allowInput: true })
    flatpickr("input.date_picker_range", {mode: 'range', dateFormat: 'd/m/Y', "locale": Portuguese })
    flatpickr("input.time_picker", {"locale": Portuguese, time_24hr: true, enableTime: true, noCalendar: true})

    toastr.options = {
      "closeButton": false,
      "debug": false,
      "newestOnTop": false,
      "progressBar": false,
      "positionClass": "toast-bottom-right",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "500",
      "hideDuration": "1000",
      "timeOut": "10000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    }

    const showToastType = {
      notice: 'success',
      success: 'success',
      alert: 'error',
      error: 'error',
      warning: 'warning',
      info: 'info'
    };

    const showToastOptions = {
      notice: {},
      alert: { "timeOut": "0", "extendedTimeOut": "0" },
      warning: { "timeOut": "0", "extendedTimeOut": "0" },
      info: {}
    };

    window.showToast = function(messages){
      for(var i = 0; i < messages.length; i++ ){
        var msg = messages[i];
        try {
          toastr[showToastType[msg[0]]](msg[1], '', showToastOptions[msg[0]]);
        } catch(err) {
          toastr.info(msg[1], '', showToastOptions[msg[0]]);
        }
      }
    };

    $('#phone_number').on('cocoon:before-insert', function(e,task_to_be_added) {
      task_to_be_added.find('input.phone').mask('(00) 0000-00009')
      task_to_be_added.find('select.select2').select2()
    })

    $('#contacts').on('cocoon:before-insert', function(e,task_to_be_added) {
      task_to_be_added.find('input.phone').mask('(00) 0000-00009')
    })

    $('#addresses').on('cocoon:before-insert', function(e,task_to_be_added) {
      task_to_be_added.find('input.cep').mask('00000-000',cepOptions)
      task_to_be_added.find('select.select2').select2()
    })

    

	 $('a.toggleExtra').click(function(e) {
		 $(e.currentTarget.parentNode.parentNode.parentNode).find(".collapse").toggle()
	 });

   window.poloni.accomplished_service.init();

   $('#avaible_external input').click(function(e) {
     if ($(this).is(':checked'))
     {
         if(!confirm("Deseja liberar acesso externo a este registro?")){
            $(this).prop( "checked", false );
         }
     }
	 });

	 $('input.updateDeadline').on("change", function(e) {
		 changeDeadLine(e.target.value);
	 });

    $('input.updateDaysTodo').on("blur", function(e) {
      changeDaysTodo(e.target.value);
	 });

   $('#services').find("select.select2[name$='[service_type_id]']").on('change', checkDeadLine)
   $('#services').find("select.select2[name$='[status]']").on('select2:selecting', change_service_status)

   $('#services').on('cocoon:before-insert', function(e,task_to_be_added) {
      task_to_be_added.find('select.select2').select2()
      task_to_be_added.find('input.cpf_cnpj').mask(fnCpfCnpj, optionsCPFCNPJ)
	    task_to_be_added.find('input.updateDeadline').on("change", function(e) {
  		  changeDeadLine(e.target.value);
  	  });
     task_to_be_added.find('input.updateDaysTodo').on("blur", function(e) {
       changeDaysTodo(e.target.value);
  	  });

  	  task_to_be_added.find('a.toggleExtra').click(function(e) {
  		  $(e.currentTarget.parentNode.parentNode.parentNode).find(".collapse").toggle()
  	  });

      task_to_be_added.find("select.select2[name$='[service_type_id]']").on('change', checkDeadLine)
      task_to_be_added.find("select.select2[name$='[status]']").on('select2:selecting', change_service_status)

     task_to_be_added.find('input.currency').each(function(item){
       window.poloni.utils.mask.currency.maskField(this);
     })

  	  setTimeout( () => {
        flatpickr($("input.date_picker").last(), { dateFormat: 'd/m/Y', "locale": Portuguese, allowInput: true })
  	  }, 200);

     task_to_be_added.find("a.coin-select").on('click', coinSelect);
    })

    $('#services').on('cocoon:before-remove', function (e, task) {
      var confirmation = confirm("Você tem certeza que deseja apagar este serviço?");

      if (confirmation === false) {
        e.preventDefault();
      }
    })
    

    $("a.coin-select").on('click', coinSelect);

    $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
      if (!$(this).next().hasClass('show')) {
        $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
      }
      var $subMenu = $(this).next('.dropdown-menu');
      $subMenu.toggleClass('show');


      $(this).parents('.dropdown.show').on('hidden.bs.dropdown', function(e) {
        $('.dropdown-submenu .show').removeClass('show');
      });

      return false;
    });

  });
}

export default Utils;

export function ptBrToFloat(value) {
  return parseFloat(value.replace(/\./gi,'').replace(/,/gi,'.'));
}

function checkDeadLine(e) {
  let selected = $(e.target).select2('data')[0];

  if (selected){
    let days = selected.element.dataset.days
    let deadline_name = null;
    let deadline_field = null;
    
    if (e.target.id.startsWith('quote_')) {
      deadline_name = '#' + e.target.id.replace('service_type_id', 'days_to_do');
      deadline_field = $(deadline_name);

      return deadline_field.val(days).trigger('blur');
    }

    deadline_name = '#' + e.target.id.replace('service_type_id', 'deadline');
    deadline_field = $(deadline_name);

    if (days !== undefined){
      let now = moment().add(days, 'days');

      if (now.day() === 0){ now = now.add(1, 'days') }
      if (now.day() === 6){ now = now.add(2, 'days') }

      deadline_field.val(now.format('DD/MM/YYYY')).trigger('change');
    }
  }
}

function changeDeadLine(dataVal) {
	let data = new Date()
	data.setDate(dataVal.substr(0,2));
	data.setMonth(dataVal.substr(3,2));
	data.setYear(dataVal.substr(6,4));

	let orderDataVal = $('input.orderdeadline').val();
	if (orderDataVal == ""){
	  $('input.orderdeadline').val(dataVal);
	  return;

	}
	let orderData = new Date()
	orderData.setDate(orderDataVal.substr(0,2));
	orderData.setMonth(orderDataVal.substr(3,2));
	orderData.setYear(orderDataVal.substr(6,4));

	if (data > orderData)
	  $('input.orderdeadline').val(dataVal)
}

function changeDaysTodo(days){
  let quoteDaysField = $('input#quote_days_to_do');
  let quoteDaysVal = quoteDaysField.val();
  if (quoteDaysVal === "") { quoteDaysVal = 0 }

  if (parseInt(quoteDaysVal) < parseInt(days)) {
    quoteDaysField.val(days)
  }
}

function confirm_service_change(e, msg, confirm) {
  let self = $(e.currentTarget);
  let newValue = e.params.args.data.id;

  if (self.data('documents') === 0 && confirm){
    Swal.fire({
      title: 'Alerta!',
      text: msg,
      icon: 'warning',
      confirmButtonText: 'Sim',
      confirmButtonColor: '#007bff',
      showCancelButton: true,
      cancelButtonText: 'Não'
    }).then((result) => {
      self.trigger('select2:close');
      self.select2('close');

      if (result.isConfirmed) {
        self.val(newValue).trigger('change');
        set_order_to_awaiting_billing(self);
      }
    })
  } else {
    self.val(newValue).trigger('change');
    self.trigger('select2:close');
    self.select2('close');
    set_order_to_awaiting_billing(self);
  }

}

function set_order_to_awaiting_billing(target){
  let services_status = {awaiting_billing: 0, finished: 0, canceled: 0, opens: 0};
  let order_status = $('#order_status');

  $('#services').find("select.select2[name$='[status]']").each((i, item) => {

    switch ($(item).select2('val')) {
      case 'awaiting_billing':
        services_status.awaiting_billing += 1;
        break;
      case 'finished':
        services_status.finished += 1;
        break;
      case 'canceled':
        services_status.canceled += 1;
        break;
      default:
        services_status.opens += 1;
        break;
    }

  })

  if (services_status.opens > 0){ return }

  if (services_status.awaiting_billing > 0){
    order_status.val('awaiting_billing').trigger('change');
    return
  }

  if (services_status.finished > 0){
    order_status.val('finished').trigger('change');
    return
  }

  if (services_status.canceled > 0){
    order_status.val('canceled').trigger('change');
    return
  }

}

const message_no_document = "Esse serviço não possui nenhum documento, você tem certeza que deseja"

function change_service_status(e){
  let newValue = e.params.args.data.id;

  switch (newValue) {
    case 'awaiting_billing':
      e.preventDefault();
      confirm_service_change(e, message_no_document+" aguardar o faturamento?", true)
      break;
    case 'finished':
      e.preventDefault();
      confirm_service_change(e, message_no_document+" concluir?", true)
      break;
    case 'canceled':
      e.preventDefault();
      confirm_service_change(e, message_no_document+" cancelar?", false);
      break;
    default:
  }
}

function waitSubmiting(e){
  if (window.poloni.save){
    e.preventDefault(); e.stopPropagation(); return;;
  }

  window.poloni.save = true;

  $('button[name=commit]').hide();
  $('button[name=saving]').show();

  $(e.currentTarget).unbind('click').trigger('click');

  setTimeout( () => {
    $(e.currentTarget).on('click', waitSubmiting);

    window.poloni.save = false;

    $('button[name=commit]').show();
    $('button[name=saving]').hide();
  }, 5000);
}

function changePerPage(e){
  var params = new URLSearchParams(location.search);
  params.set('per_page', e.currentTarget.value);
  window.location.search = params.toString();
}

function coinSelect(e){
  e.preventDefault();

  var coin = e.currentTarget.dataset.value
  var coin_symbol = e.currentTarget.innerText

  var text = $(e.target.parentElement.parentElement.parentElement.firstChild)
  text.text(coin_symbol)

  $(e.currentTarget).parents('.form-group').siblings().find('input').val(coin) 
}