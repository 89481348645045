const Swal = require('sweetalert2');

const Services = function() {
  var pub = {};
  var priv = {};

  const message_no_document = "Existem serviços selecionados que não possuem nenhum documento, você tem certeza que deseja"

  priv.validateStatusChange = function(e) {
    e.preventDefault();
    e.stopPropagation();

    var status_id = $(e.currentTarget).data('status-id');
    var service_ids = [];
    var services_boxes = $("[name='service[ids][]']:checked");

    if (status_id === 3 || status_id === 4){
      let msg = '';

      if (status_id === 3){
        msg = message_no_document +  ' aguardar o faturamento?'
      } else {
        msg = message_no_document + ' concluir?'
      }

      if(services_boxes.filter('[data-documents="0"]').length > 0){
        Swal.fire({
          title: 'Alerta!',
          text: msg,
          icon: 'warning',
          confirmButtonText: 'Sim',
          confirmButtonColor: '#007bff',
          showCancelButton: true,
          cancelButtonText: 'Não'
        }).then((result) => {
          if (result.isConfirmed) { priv.handleChangeStatus(e) }
        })
      }
    } else {
      priv.handleChangeStatus(e)
    }
  }

  priv.handleChangeStatus = function(e) {
    e.preventDefault();
    e.stopPropagation();

    var status_id = $(e.currentTarget).data('status-id');
    var service_ids = [];
    var services_boxes = $("[name='service[ids][]']:checked");

    services_boxes.each((i, item) => {
      service_ids.push(item.value)
    });

    if (service_ids.length === 0){
      return false;
    }

    window.showToast([['info','Aguarde Atualizando Situações...']])

    $.ajax({
      url: priv.remote_url,
      dataType: 'json',
      type: "PUT",
      data: {ids: service_ids, status_id: status_id},
      success: function(data){
        window.showToast([['success','Atualizado Situações com Successo!']])
        setTimeout(function(){ window.location.reload() }, 2000);
      },
      error: function(data){
        window.showToast([['error','Ocorreu um erro ao atualizar as Situações!']])
      }
    })

  }

  priv.validateServicesChange = function(e){
    e.preventDefault();
    e.stopPropagation();

    var form = $('form#formUpdateAllServices');
    
    var service_ids = [];
    var services_boxes = $("[name='service[ids][]']:checked");

    services_boxes.each((i, item) => {
      service_ids.push(item.value)
    });

    var description = form.find('#service_description').val();

    if (service_ids.length === 0) {
      return false;
    }

    window.showToast([['info', 'Aguarde Atualizando Descrições...']])

    $.ajax({
      url: priv.remote_url,
      dataType: 'json',
      type: "PUT",
      data: { ids: service_ids, description: description },
      success: function (data) {
        window.showToast([['success', 'Atualizado Situações com Successo!']])
        setTimeout(function () { window.location.reload() }, 2000);
      },
      error: function (data) {
        window.showToast([['error', 'Ocorreu um erro ao atualizar as Descrições!']])
      }
    })

  }

  pub.init = () => {
    $('a[data-toggle="collapse"]').on('click', function(){
      $(this).find(".collapse").toggle()
    })

    priv.remote_url = $('a[data-status-id]').first().data("url")
    $('a[data-status-id]').on('click', priv.validateStatusChange)

    $('button#submitUpdateAllServices').on('click', priv.validateServicesChange)

    $('td input[data-documents="0"]').parent().addClass('table-warning')
  }

  return pub;
}()

export default Services
