import { ptBrToFloat } from './utils.js';

const AccomplishedService = function() {
  var pub = {};
  var priv = {};

  priv.handleChange = function(e) {
     var total = 0;

     if (e.target.id.indexOf("value") < 0){
        total = ptBrToFloat($('#AccomplishedServiceValue input')[0].value) * e.target.value.replace(',','.')
     } else {
        total = $('#AccomplishedServiceValue input')[1].value.replace(',','.') * ptBrToFloat(e.target.value)
     }

     if ($('#accomplished_services_cost_total')){
       $('#accomplished_services_cost_total').val(total.toLocaleString("pt-br"));
     }

     if ($('#accomplished_services_fee_total')){
       $('#accomplished_services_fee_total').val(total.toLocaleString("pt-br"));
     }

     if ($('#accomplished_services_post_office_total')){
       $('#accomplished_services_post_office_total').val(total.toLocaleString("pt-br"));
     }
   }

   pub.init = (fields) => {
     if (fields === undefined){ fields = '#AccomplishedServiceValue input' }
     $(fields).on("change", priv.handleChange);

     window.poloni.save = false
     // $('button[name=commit]').unbind('click');
     $('button[name=commit]').on('click', function(e){
       if (window.poloni.save){
         e.preventDefault(); e.stopPropagation(); return;;
       }
       window.poloni.save = true;

       $('button[name=commit]').hide();
       $('button[name=saving]').show();
     });

   }

  return pub;
}()

export default AccomplishedService
