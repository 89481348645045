const Counters = function () {
  var pub = {};
  var priv = {};

  priv.setCounters = (counters) => {
    const dataCounters = $("[data-counter]");

    Object.keys(counters.orders).forEach( (key) => {
      let dataCounter = dataCounters.filter( function(){
        return $(this).attr('data-counter') == key
      })

      dataCounter.text(counters.orders[key]);
    })
  }

  priv.loadCounter = (counterFiled) => {
    $.getJSON(counterFiled.data('counter-url')).done( result => priv.setCounters(result) ) 
  }

  pub.init = () => { 
    $("[data-counter-url]").each(function(){ priv.loadCounter($(this)) })
  };

  return pub;
}()

export default Counters;