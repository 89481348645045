import { Portuguese } from "flatpickr/dist/l10n/pt.js"
import moment from 'moment';

const Boleto = function() {
  var pub = {};
  var priv = {};

  priv.checkBoleto = function(data){

    if (data.status !== 'generate_billet' && data.boleto_attached){
      setTimeout(function(){ window.location.reload(true) }, 300);
      return true
    }

    setTimeout(priv.verifyBoleto, 15000);
  }

  priv.verifyBoleto = function(){
    $.getJSON(priv.verifyBoletoUrl, priv.checkBoleto)
  }

  pub.setSplittedPayment = function(){
    priv.split_payment();
  }

  priv.split_payment = function(){
    var splitted_first_due_at = $('input#billing_due_at').val();
    var nr_boleto = $('input#billing_nr_boleto').val();
    var splitted_count = $('select#billing_installment_count').val();
    var splitted_total = window.poloni.utils.to_float($('input#billing_total_value').val());
    var splitted_size = 1;
    var coocon_div = $("#splitted_payments");

    coocon_div.find('a.remove_fields').trigger('click');

    if (splitted_count === 1) {
      return;;
    }

    var splitted_value = (splitted_total / splitted_count).toFixed(2);
    var first_splitted_value = (splitted_total - (splitted_value * (splitted_count - 1)));

    coocon_div.on('cocoon:before-insert', function () {
      setTimeout(() => {
        window.poloni.utils.mask.currency.maskField('input.currency');
      }, 200);
    })

    for (let index = 0; index < splitted_count; index++) {
      coocon_div.find('a.add_fields').trigger('click')

      coocon_div.find("[name$='[due_at]']").last().val(splitted_first_due_at);

      if (index === 0) {
        coocon_div.find("[name$='[installment_value]']").val(first_splitted_value.toFixed(2));
      } else {
        var fr = flatpickr(coocon_div.find("[name$='[due_at]']").last(), { dateFormat: 'd/m/Y', "locale": Portuguese, allowInput: true })
        fr.changeMonth(index);
        fr.setDate(moment(splitted_first_due_at, 'DD/MM/YYYY').add(index, 'month').format('DD/MM/YYYY'), console.log, 'd/m/Y');
        coocon_div.find("[name$='[installment_value]']").last().val(splitted_value);
      }
      
      coocon_div.find("[name$='[installment_number]']").last().val(splitted_size)
      coocon_div.find("[name$='[nr_boleto]']").last().val(nr_boleto+"-"+splitted_size)

      splitted_size++;
    }

    $('#splitted_payment').show()
  }

  pub.init = function(verifyBoleto){
    if($('select#billing_status').val() === 'generate_billet'){
      window.showToast([['success', 'Gerando Boleto...']]);

      setInterval(function(){
        window.showToast([['success', 'Gerando Boleto...']])
      }, 10000)

      $('input').attr('disabled', true);

      $('button[name=saving]').show();
      $('button[name=commit]').hide();

      setTimeout(priv.verifyBoleto, 10000);
    }

    priv.verifyBoletoUrl = verifyBoleto;
  }

  return pub;
}()

export default Boleto
