const Mask = {
  currency: function(){
    let priv = {
      mask: '###.A###.A###.A###.A##0,00',
      onChange: function(value, e) {
        var target = e.target,
        position = target.selectionStart; // Capture initial position
        target.value = value.replace(/(?!^)-/g, '').replace(/^\./, '').replace(/^-\./, '-');

        target.selectionEnd = position; // Set the cursor back to the initial position.
      }
    }

    let options = {
        reverse: true,
        translation: {
          '#': {
            pattern: /-|\d/,
            recursive: true
          },
          'A': {
            pattern: '-',
            optional: true
          }
        },
        onChange: priv.onChange
    };

    let pub = {};

    pub.maskField = (field) => {
      $(field).mask(priv.mask, options).trigger('change')
    }

    pub.formatInitFields = (fields) => {
      $(fields).each(function(){
          var self = $(this)
          self.val(self.val().replace(/(?!^)-/g, '').replace(/^\./, '').replace(/^-\./, '-'))
      })
    }

    return pub
  }()
}

export default Mask;
