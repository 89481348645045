const Checkboxes = function(){
  var pub = {};
  var priv = {};

  priv.slaves = undefined;

  priv.change_master = function(elem){
    $(priv.slaves).trigger('click')
  }

  priv.markall = function(e){
    e.preventDefault(); e.stopPropagation();
    $(priv.slaves+":not(:checked)").trigger('click');
  }

  priv.unmarkall = function(e){
    e.preventDefault(); e.stopPropagation();
    $(priv.slaves+":checked").trigger('click');
  }

  priv.toggle = function(e){
    e.preventDefault(); e.stopPropagation();
    $(priv.slaves).trigger('click');
  }

  pub.init = function(){
    $('button#btncheckmaster').on('click', function(e){
      e.preventDefault(); e.stopPropagation();
      $(this).find('[data-checkbox-master]').trigger('click');
    })
    $('input[type=checkbox][data-checkbox-master]').each(function(){
      var checkboxes_master = $(this);

     checkboxes_master.bind('change', priv.change_master);
     
     $('#'+checkboxes_master.attr('id')+'div [data-markall]').bind('click', priv.markall);
     $('#'+checkboxes_master.attr('id')+'div [data-unmarkall]').bind('click', priv.unmarkall);
     $('#'+checkboxes_master.attr('id')+'div [data-toggle]').bind('click', priv.toggle);

     priv.slaves = checkboxes_master.data('checkboxSlaves');
   })
  }

  return pub;
}()

export default Checkboxes;
