const Nfse = function() {
  var pub = {};
  var priv = {};

  priv.checkLote = function(data){
    if (data.situacao_lote === 'autorizado'){
      window.showToast([['success','Nota Fiscal emitida com Successo!']])
      setTimeout(function(){ window.location.reload(true) }, 7000);
      return true
    }

    if (data.situacao_lote === 'criticado'){
      window.showToast([['error', 'Existem criticas na Nota Fiscal!']])

      var criticas = data.criticas.split('\n');
      var content = '';

      criticas.forEach(function(item){
        content = content + '<p class="error">' + item + '</p>'
      })

      $('#NfseLoteModal #criticas').html(content)
      $('#NfseLoteModal').modal('show');
      return false
    }

    setTimeout(priv.verifyLote, 15000);
  }

  priv.verifyLote = function(){
    window.showToast([['info', 'Verificando a emissão da Nota Fiscal!']])
    $.getJSON(priv.verifyLoteUrl, priv.checkLote)
  }

  pub.init = function(verifyLote){
    if (verifyLote !== 'false'){
      priv.verifyLoteUrl = verifyLote;
      setTimeout(priv.verifyLote, 5000);
    }
  }

  return pub;
}()

export default Nfse
